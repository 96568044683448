<template>
  <div>
    <router-link
      :to="{
        name: 'UserMarketProduct',
        params: {
          familyId: $route.params.familyId,
          marketId: $route.params.marketId,
          productId: this.data.ppl.productproducer.id,
        },
      }"
    >
      <strong>{{ getProductName() }}</strong>
    </router-link>
    <router-link
      :to="{
        name: 'UserMarketProduct',
        params: {
          familyId: $route.params.familyId,
          marketId: $route.params.marketId,
          productId: this.data.ppl.productproducer.id,
        },
      }"
    >
      <div>{{ getVariantName() }}</div>
    </router-link>
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "UserProductTableVariantTitle",
  data() {
    return {
      data: {},
    };
  },
  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },
  methods: {
    getProductName() {
      return get(
        this.data,
        `ppl.productproducer.translations.${this.lang}.name`
      );
    },
    getVariantName() {
      return get(this.data, `ppl.translations.${this.lang}.name`);
    },
  },
};
</script>
